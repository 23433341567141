<template>

  <div>
    <b-row class="tab-card-fixed-height">
      <b-col>
        <b-tabs id="assignService">
          <b-tab
            v-for="service in assignedServices"
            :key="service.id"
            :title="service.id == constants.OTHER_SERVICE_ID ? service.other_service ? service.other_service : service.service_name : service.service_name"
            class="mt-md-3"
            @click="collapseAssign(service)"
          >
            <b-form-group
              :label="t('Primary User')"
              label-for="h-primary-user"
              label-cols-md="2"
            >
              <v-select
                v-model="assignServiceForm.primary_user_id"
                class="p-0 border-0"
                multiple
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="company_user_name"
                :reduce="ul => ul.companyuserid"
                :placeholder="t('Select Primary User')"
                :options="primaryUsersList"
              />
            </b-form-group>
            <b-form-group
              :label="t('Secondary User')"
              label-for="h-secondary-user"
              label-cols-md="2"
            >
              <v-select
                v-model="assignServiceForm.secondary_user_id"
                class="p-0 border-0"
                multiple
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="company_user_name"
                :reduce="ul => ul.companyuserid"
                :placeholder="t('Select Secondary User')"
                :options="secondaryUsersList"
              />
            </b-form-group>
            <div class="text-center">
              <b-button
                variant="success"
                class="m-2"
                @click="assignServiceSubmit"
              >
                {{ t('Assign') }}
              </b-button>

            </div>
            <!-- <b-button
              variant="outline-danger"
              class="m-2"
              @click="assignServiceCancel"
            >
              Cancel
            </b-button> -->
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import 'bootstrap-vue'
import vSelect from 'vue-select'
import { useUtils } from '@core/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import constants from '@/constants'
import { hideLoader, showLoader } from '@/utils/common'

export default {
  name: 'AssignServices',
  components: {
    vSelect,
  },
  props: {
    services: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      constants,
      loginUser: JSON.parse(localStorage.getItem('userData')).companyuserid,
      usersListFlag: true,
      assignServiceCollapse: false,
      assignServiceForm: {
        service_id: '',
        lead_id: this.$route.params.id,
        primary_user_id: [],
        secondary_user_id: [],
      },
      secondaryUsersList: [],
      primaryUsersList: [],
      service_name: '',
      assignedServices: [],
    }
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  mounted() {
    this.collapseAssign(this.services[0])
  },
  methods: {
    async collapseAssign(serviceVal) {
      showLoader()
      this.assignServiceForm = {
        service_id: '',
        lead_id: this.$route.params.id,
        primary_user_id: [],
        secondary_user_id: [],
      }
      this.assignServiceForm.service_id = serviceVal.id
      this.assignServiceForm.primary_user_id = serviceVal.primary_user
      this.assignServiceForm.secondary_user_id = serviceVal.secondary_user
      await this.getPrimaryUsers()
      await this.getSecondaryUsers()
      await this.getAssignedServices()
      hideLoader()
    },
    async getPrimaryUsers() {
      await store.dispatch('vendorStore/assignServicesUserList', this.assignServiceForm).then(response => {
        this.primaryUsersList = response.data.data
      })
    },
    async getSecondaryUsers() {
      await store.dispatch('vendorStore/assignServicesUserNotInList', this.assignServiceForm).then(response => {
        this.secondaryUsersList = response.data.data
      })
    },
    async getAssignedServices() {
      await store.dispatch('vendorStore/getAssignedServices', this.assignServiceForm).then(response => {
        if (response.data.data.length > 0) {
          this.assignedServices = response.data.data
        } else {
          this.assignedServices = this.services
        }
      })
    },
    // assignServiceCancel() {
    //   this.$root.$emit('bv::toggle::collapse', 'assign_service')
    // },
    assignServiceSubmit() {
      if (!this.assignServiceForm.primary_user_id.length > 0 && !this.assignServiceForm.secondary_user_id.length > 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Please Select Users!',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        return
      }
      store.dispatch('vendorStore/assignServiceSubmit', this.assignServiceForm).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.$root.$emit('refreshLeadData')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'Success',
              variant: 'success',
              text: '',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something Went Wrong!',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-card-fixed-height{
    height: 320px;
    margin-bottom: 10px;
}
.tab-card-fixed-height::-webkit-scrollbar{
    display: none;
}
.vs__dropdown-menu{
  max-height: 50px !important;
}
</style>
