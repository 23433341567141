<template>
  <b-modal
    id="addUnit"
    title="Add New Unit"
    hide-footer
    size="xl"
  >
    <div>
      <validation-observer
        ref="addUnit"
      >
        <b-form
          autocomplete="off"
          @submit.prevent="addUnit"
        >
          <b-row>
            <b-col md="6">
              <!-- Title -->
              <b-col cols="12">
                <b-form-group
                  :label="t('Title')"
                  label-for="vi-Title"
                  class="required"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Title"
                    vid="vi-Title"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="vi-Title"
                        v-model="form.vendor_unit_title"
                        :class="errors[0] ? 'border-red' : ''"
                        type="text"
                        autocomplete="off"
                        :placeholder="t('Title')"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Address -->
              <b-col cols="12">
                <b-form-group
                  :label="t('Address')"
                  label-for="vi-address"
                  class="required"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Address Line 1"
                    vid="vi-address"
                    :rules="form.address ? '' : 'required'"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors[0] ? 'border-red-gmap' : ''"
                    >
                      <GmapAutocomplete
                        id="unitAddressGmap"
                        label-for="vi-address"
                        class="form-control"
                        :placeholder="t('Enter a Location')"
                        @keydown.enter.prevent
                        @place_changed="setPlace"
                        @change="changeAddress"
                      />

                    </b-input-group>
                    <small class="text-danger">{{ t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-row class="mx-0">
                <!-- Country -->
                <b-col cols="6">
                  <b-form-group
                    :label="t('Country')"
                    label-for="vi-Country"
                    class="required"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Country"
                      vid="vi-Country"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="vi-Country"
                          v-model="form.country_name"
                          :disabled="country_disable"
                          :class="errors[0] ? 'border-red' : ''"
                          type="text"
                          autocomplete="off"
                          :placeholder="t('Country')"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- State -->
                <b-col cols="6">
                  <b-form-group
                    :label="t('State')"
                    label-for="vi-State"
                    class="required"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="State"
                      vid="vi-State"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="vi-State"
                          v-model="form.state_name"
                          :disabled="state_disable"
                          :class="errors[0] ? 'border-red' : ''"
                          type="text"
                          autocomplete="off"
                          :placeholder="t('State')"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- City -->
                <b-col cols="6">
                  <b-form-group
                    :label="t('City')"
                    label-for="vi-City"
                    class="required"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="City"
                      vid="vi-City"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="vi-City"
                          v-model="form.city_name"
                          :disabled="city_disable"
                          :class="errors[0] ? 'border-red' : ''"
                          type="text"
                          autocomplete="off"
                          :placeholder="t('City')"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- zipcode -->
                <b-col cols="6">
                  <b-form-group
                    :label="t('Zip Code')"
                    label-for="vi-zipcode"
                    class="required"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Zip code"
                      vid="vi-zipcode"
                      rules="required|min:6"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="vi-zipcode"
                          v-model="form.zipcode"
                          :disabled="zip_disable"
                          type="text"
                          autocomplete="off"
                          :class="errors[0] ? 'border-red' : ''"
                          :placeholder="t('Zip Code')"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ t(errors[0]) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-col
                cols="12"
                class="text-center mt-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  {{ t('Submit') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                >
                  {{ t('Cancel') }}
                </b-button>
              </b-col>
            </b-col>
            <b-col
              md="6"
              class="pb-3"
            >
              <GmapMap
                :center="center"
                :zoom="12"
                style="width: 100%; height: 360px; margin-top: 20px"
              >
                <GmapMarker
                  v-for="(m, index) in markers"
                  :key="index"
                  :position="m.position"
                  @click="center = m.position"
                />
              </GmapMap>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import store from '@/store'
import {
  showLoader, hideLoader, successToast, failedToast,
} from '@/utils/common'
import constants from '@/constants'

export default {
  name: 'AddUnit',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    // addunitdata: {
    //   type: Object,
    //   required: false,
    // },
    // eslint-disable-next-line vue/require-default-prop
    editunitdata: {
      type: Object,
      required: false,
    },
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      required,
      zip_disable: false,
      country_disable: false,
      state_disable: false,
      city_disable: false,
      markers: [],
      center: { lat: 23.0665966, lng: 72.5321012 },
      form: {
        vendor_id: this.$route.params.id,
        vendor_unit_id: this.editunitdata ? this.editunitdata.vendor_unit_id : '',
        vendor_unit_title: '',
        address: '',
        editaddress: '',
        country_name: '',
        zipcode: '',
        state_name: '',
        city_name: '',
      },
    }
  },
  watch: {
    // addunitdata: {
    //   deep: true,
    //   handler(val) {
    //     this.form.vendor_id = val.vendor_id
    //   },
    // },
    editunitdata: {
      deep: true,
      handler(val) {
        this.form.vendor_unit_id = val.vendor_unit_id
        this.form.vendor_unit_title = val.unit_title
        this.form.address = val.address
        this.form.editaddress = val.address
        this.form.country_name = val.country_name
        this.form.state_name = val.state_name
        this.form.city_name = val.city_name
        this.form.zipcode = val.zipcode
      },
    },
  },
  mounted() {
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      // eslint-disable-next-line eqeqeq
      if (modalId == 'addUnit') {
        document.getElementById('unitAddressGmap').value = this.form.editaddress
      }
    })
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      // eslint-disable-next-line eqeqeq
      if (modalId == 'addUnit') {
        this.zip_disable = false
        this.country_disable = false
        this.state_disable = false
        this.city_disable = false
        this.form = {
          vendor_id: this.$route.params.id,
          vendor_unit_id: this.editunitdata ? this.editunitdata.vendor_unit_id : '',
          vendor_unit_title: '',
          address: '',
          editaddress: '',
          country_name: '',
          zipcode: '',
          state_name: '',
          city_name: '',
        }
      }
    })
  },
  methods: {
    changeAddress(val) {
      // eslint-disable-next-line eqeqeq
      if (val.target.value == '') {
        this.form.zipcode = ''
        this.zip_disable = false
        this.form.country_name = ''
        this.country_disable = false
        this.form.city_name = ''
        this.city_disable = false
        this.form.state_name = ''
        this.state_disable = false
      }
    },
    setPlace(place) {
      this.currentPlace = place
      this.form.address = place.formatted_address
      this.form.city_name = ''
      this.form.state_name = ''
      this.form.country_name = ''
      this.form.zipcode = ''
      this.form.latitude = place.geometry.location.lat()
      this.form.longitude = place.geometry.location.lng()
      place.address_components.forEach(address => {
        if (address.types.includes('postal_code')) {
          this.form.zipcode = address.long_name
        }
        if (address.types.includes('administrative_area_level_1')) {
          this.form.state_name = address.long_name
        }
        if (address.types.includes('locality')) {
          this.form.city_name = address.long_name
        }
        if (address.types.includes('country')) {
          this.form.country_name = address.long_name
        }
      })
      if (this.form.zipcode !== '') { this.zip_disable = true } else { this.zip_disable = false }
      if (this.form.country_name !== '') { this.country_disable = true } else { this.country_disable = false }
      if (this.form.city_name !== '') { this.city_disable = true } else { this.city_disable = false }
      if (this.form.state_name !== '') { this.state_disable = true } else { this.state_disable = false }
      this.addMarker()
      this.$emit('getCreateLead', this.form)
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        }
        this.markers.push({ position: marker })
        this.center = ''
        this.center = marker
        this.currentPlace = null
      }
    },
    addUnit() {
      this.$refs.addUnit.validate().then(success => {
        if (success) {
          if (this.editunitdata) {
            this.unitUpdateSubmit()
          } else {
            this.unitAddSubmit()
          }
        }
      })
    },
    async unitAddSubmit() {
      showLoader()
      await store.dispatch('unitStore/addUnit', this.form).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          successToast(response.data.msg)
          this.$bvModal.hide('addUnit')
          this.$emit('loadUnits')
          this.form = {
            vendor_id: this.$route.params.id,
            vendor_unit_title: '',
            address: '',
            country_name: '',
            zipcode: '',
            state_name: '',
            city_name: '',
          }
          hideLoader()
        }
      }).catch(() => {
        failedToast('Something went wrong!')
        hideLoader()
      })
    },
    async unitUpdateSubmit() {
      showLoader()
      await store.dispatch('unitStore/updateUnit', this.form).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          successToast(response.data.msg)
          this.$bvModal.hide('addUnit')
          this.$emit('loadUnits')
          this.form = {
            vendor_id: this.$route.params.id,
            vendor_unit_title: '',
            address: '',
            country_name: '',
            zipcode: '',
            state_name: '',
            city_name: '',
          }
          hideLoader()
        }
      }).catch(() => {
        failedToast('Something went wrong!')
        hideLoader()
      })
    },
  },
}
</script>

<style>
.pac-container.pac-logo.hdpi{
  z-index: 9999;
}
</style>
