<template>
  <div>
    <b-row class="tab-card-fixed-height">
      <b-col
        v-if="!log.length"
        xl="12"
        class="padding-b-28"
      >
        <b-card class="h-100">
          <b-row class="h-100 align-items-center">
            <b-col
              cols="12"
              class="pr-0 text-center"
            >
              {{ t('No Log Found') }}
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <app-timeline class="p-1 w-100">
        <app-timeline-item
          v-for="(remark, index) in log"
          :key="index"
          :variant="'success'"
          class="d-flex justify-content-between"
        >
          <span>
            {{ remark.remarks }}
          </span>
          <span>
            {{ activityDateFormat(remark.updated_at) }}
          </span>
        </app-timeline-item>
      </app-timeline>
    </b-row>
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { useUtils } from '@core/libs/i18n'
import store from '@/store'
import constants from '@/constants'
import {
  images, docs, pdf, hideLoader, showLoader, activityDateFormat,
} from '@/utils/common'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: ['close_remark', 'lead_id', 'activity_ids', 'is_edit'],
  data() {
    return {
      images,
      docs,
      pdf,
      log: [],
      remarkAPI: '',
      form: {
        activity_for_id: this.activity_ids.activity_for_id ? this.activity_ids.activity_for_id : '',
        activity_for: this.activity_ids.activity_for ? this.activity_ids.activity_for : '',
        lead_id: this.activity_ids.lead_id ? this.activity_ids.lead_id : '',
        vendor_id: this.activity_ids.vendor_id ? this.activity_ids.vendor_id : '',
        opportunity_id: this.activity_ids.opportunity_id ? this.activity_ids.opportunity_id : '',
        vendor_unit_id: this.activity_ids.vendor_unit_id ? this.activity_ids.vendor_unit_id : '',
      },
    }
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  mounted() {
    this.getAssignLog()
    this.$root.$on('refreshLeadData', () => {
      this.getAssignLog()
    })
  },
  methods: {
    activityDateFormat(date) {
      return activityDateFormat(date)
    },
    async getAssignLog() {
      showLoader()
      await store.dispatch('vendorStore/getAssignLog', this.form).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.log = response.data.data
          hideLoader()
        }
      }).catch(() => {
        hideLoader()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-card-fixed-height{
    height: 320px;
    overflow: auto;
    margin-bottom: 10px;
}
.tab-card-fixed-height::-webkit-scrollbar{
   display: none;
}
</style>
