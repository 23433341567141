<template>
  <div>
    <b-table
      sticky-header
      responsive="true"
      :items="items.data"
      :fields="userFields"
    >
      <template #cell(id)="data">
        <a
          href="javascript:void(0)"
          @click="$router.push(`/opportunity/${data.item.lead_opportunity_id}`)"
        >
          {{ data.item.id }}
        </a>
      </template>
      <template #cell(name)="data">
        <a
          href="javascript:void(0)"
          @click="$router.push(`/opportunity/${data.item.lead_opportunity_id}`)"
        >
          {{ data.item.name }}
        </a>
      </template>
      <template #cell(service_name)="data">
        <span class="">
          <b-badge variant="success">
            {{ data.item.service_name }}
          </b-badge>
        </span>
      </template>
      <template #cell(lead_assign_name)="data">
        <span class="">{{ data.item.lead_assign_name | arrayToStringVal }}</span>
      </template>
      <template
        v-if="'data' in items && !items.data.length"
        #table-caption
      >
        <div
          class="text-center"
        >
          {{ t('No Record Found') }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import { hideLoader, showLoader } from '@/utils/common'
import constants from '@/constants'
import store from '@/store'

export default {
  props: ['lead_id', 'vendor_id'],
  data() {
    return {
      items: [],

    }
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  computed: {
    userFields() {
      return [
        { key: 'id', label: this.$i18n.t('Id') },
        { key: 'name', label: this.$i18n.t('Title') },
        { key: 'service_name', label: this.$i18n.t('Service Name') },
        { key: 'lead_assign_name', label: this.$i18n.t('Lead Assign Name') },
        { key: 'opporunity_status_name', label: this.$i18n.t('Status') },
      ]
    },
  },
  mounted() {
    this.getOpportunity()
    this.$root.$on('refreshLeadData', () => {
      this.getOpportunity()
    })
  },
  methods: {
    async getOpportunity() {
      showLoader()
      await store.dispatch('opportunityStore/getOpportunityListByLeadId', { lead_id: this.lead_id, vendor_id: this.vendor_id }).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.items = response.data
        }
      }).catch(() => {
      })
      hideLoader()
    },
  },
}
</script>

<style>

</style>
